/* collapsible tree */
.tree {
    .border-top-radius(@border-radius-base);
    .border-left-radius(@border-radius-base);
    .border-bottom-radius(@border-radius-base);
    .border-right-radius(@border-radius-base);
    margin-bottom: 10px;
    min-height: 20px;
    overflow-y: auto;
    ul {
        padding-left : 30px;
    }
    > ul {
        padding-left: 0;
    }
    a {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
    }
    li {
        list-style-type: none;
        margin: 0px 0;
        padding: 4px 0px 0px 2px;
        position: relative;
        &::before, &::after {
            content: '';
            left: -20px;
            position: absolute;
            right: auto;
        }
        &::before {
            border-left: 1px solid @gray-light;
            bottom: 50px;
            height: 100%;
            top: 0;
            width: 1px;
            .transition("border-color 0.1s ease 0.1s");
        }
        &::after {
            border-top: 1px solid @gray-light;
            height: 20px;
            top: 13px;
            width: 23px;
            .transition("border-color 0.1s ease 0.1s");
        }
        span {
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border: 1px solid @gray-light;
            border-radius: 5px;
            display: inline-block;
            line-height: 14px;
            padding: 2px 4px;
            text-decoration: none;
            .transition(~"color 0.2s ease 0.1s, background-color 0.2s ease 0.1s, border-color 0.3s ease 0.2s");
        }
        &.parent_li > span {
            cursor: pointer;
            /*Time for some hover effects*/
            &:hover {
                background-color: darken(@brand-orange, 5%);
                border: 1px solid darken(@brand-orange, 10%);
                color: #fff;
            }
            &:hover+ul li {
                &::after { border-top-color: @brand-orange; }
                &::before { border-left-color: @brand-orange; }
            }
            &:hover+ul li span {
                background: lighten(@brand-orange, 35%);
                border: 1px solid lighten(@brand-orange, 10%);
                color: #000;
            }
        }
        /*Remove connectors after last child*/
        &:last-child::before {
            height: 30px;
        }

    }
    li.current > a {
        font-weight: bolder;
    }
    /*Remove connectors before root*/
    > ul > li::before, > ul > li::after {
        border: 0;
    }
}
